class myDataView extends DataView {
    shift: number;
    constructor (e, t, i, s) {
      super(e, t, i),
        (this.shift = 0),
        (this.setUint8 = (e, t) => super.setUint8(e + this.shift, t)),
        (this.setUint16 = (e, t, i) => super.setUint16(e + this.shift, t, i)),
        (this.setUint32 = (e, t, i) => super.setUint32(e + this.shift, t, i)),
        (this.setInt8 = (e, t) => super.setInt8(e + this.shift, t)),
        (this.setInt16 = (e, t, i) => super.setInt16(e + this.shift, t, i)),
        (this.setInt32 = (e, t, i) => super.setInt32(e + this.shift, t, i)),
        (this.setFloat32 = (e, t, i) =>
          super.setFloat32(e + this.shift, t, i)),
        (this.setFloat64 = (e, t, i) =>
          super.setFloat64(e + this.shift, t, i)),
        (this.shift = null != s ? s : 0)
    }
  }

export function getTempPacketV3(e) 
{
  var buffer = new ArrayBuffer(1150);
  var bufferView = new DataView(buffer);
  e += 1
  const t = buffer.byteLength - e
  let i = new myDataView(buffer, t, e, 1)
  return bufferView.setUint8(t, 34), i
}

export function fillMovePacket (e, t, i, s, r) 
{
  let a = 1280,
    d = 720,
    h = 7
  i && (h = 5)
  let c = 0
  return (
    5 == h && (c = 4),
    e.setUint32(t + 0, h, !0),
    e.setUint16(t + 4, s, !1),
    e.setUint16(t + 6, r, !1),
    e.setUint16(t + 8, 0, !1),
    e.setUint32(t + 10 + c, 0, !1),
    c && (e.setUint16(t + 10, a, !1), e.setUint16(t + 12, d, !1)),
    t + 26
  )
}