import * as Ably from 'ably/promises';
export type AgentID = string;

export enum ReceiverEnum {
    BROADCAST = "BROADCAST"
}

export type ReceiverList = AgentID | AgentID[] | ReceiverEnum.BROADCAST;


export class Receiver {

    private receiver: ReceiverList;

    constructor(receiver: ReceiverList) {
        this.receiver = receiver;
    }
    isBroadcast(): boolean {
        return this.receiver === ReceiverEnum.BROADCAST;
    }
    isDirect(): boolean {
        return this.receiver !== ReceiverEnum.BROADCAST;
    }
    isReceiverList(): boolean {
        return Array.isArray(this.receiver);
    }

    members(): AgentID[] {
        if (Array.isArray(this.receiver)) {
            return this.receiver;
        }
        return [this.receiver];
    }

    includes(agentId: AgentID): boolean {
        if (Array.isArray(this.receiver)) {
            return this.receiver.includes(agentId);
        }

        if (this.receiver === ReceiverEnum.BROADCAST) {
            return true;
        }

        return this.receiver === agentId;
    }

    setType(receiver: ReceiverList) {
        this.receiver = receiver;
    }

    getType(): ReceiverList {
        return this.receiver;
    }
}

export enum PresenceAction {
    ABSENT = "absent",
    PRESENT = "present",
    ENTER = "enter",
    LEAVE = "leave",
    UPDATE = "update"
}

export type PresenceEvent = {
    action: PresenceAction;
    agentId: AgentID;
}

const presenceActionMap: { [key: string]: PresenceAction } = {
    absent: PresenceAction.ABSENT,
    present: PresenceAction.PRESENT,
    enter: PresenceAction.ENTER,
    leave: PresenceAction.LEAVE,
    update: PresenceAction.UPDATE
}

export type FipaMessage = {
    performative: Performative;
    sender: AgentID;
    receiver: ReceiverList;
    content: any;
    language?: string;
    ontology?: string;
    protocol?: string;
    conversationId?: string;
}

export enum Performative {
    INFORM = "inform",
    REQUEST = "request",
    QUERY_IF = "query-if",
    QUERY_REF = "query-ref",
    AGREE = "agree",
    REFUSE = "refuse",
    FAILURE = "failure",
    NOT_UNDERSTOOD = "not-understood",
    CONFIRM = "confirm",
    DISCONFIRM = "disconfirm",
    PROPOSE = "propose",
    ACCEPT_PROPOSAL = "accept-proposal",
    REJECT_PROPOSAL = "reject-proposal",
    CALL_FOR_PROPOSAL = "call-for-proposal",
    CANCEL = "cancel",
    SUBSCRIBE = "subscribe"
}


export class MessageBuilder {
    private message: FipaMessage;

    constructor() {
        this.message = {
            performative: Performative.INFORM,
            sender: null,
            receiver: ReceiverEnum.BROADCAST,
            content: "",
        };
    }

    setPerformative(performative: Performative): MessageBuilder {
        this.message.performative = performative;
        return this;
    }

    setReceiver(receiver: ReceiverList): MessageBuilder {
        this.message.receiver = receiver;
        return this;
    }

    setContent(content: any): MessageBuilder {
        this.message.content = content;
        return this;
    }

    setLanguage(language: string): MessageBuilder {
        this.message.language = language;
        return this;
    }

    setOntology(ontology: string): MessageBuilder {
        this.message.ontology = ontology;
        return this;
    }

    setProtocol(protocol: string): MessageBuilder {
        this.message.protocol = protocol;
        return this;
    }

    setConversationId(conversationId: string): MessageBuilder {
        this.message.conversationId = conversationId;
        return this;
    }

    build(): FipaMessage {
        return this.message;
    }
}


export type ContributionFilter = (message: FipaMessage) => boolean;


export interface ContributionFilterOptions {
    untilAttach?: boolean;
}

export abstract class AbstractAgent {
   
    private ably: Ably.Realtime | null = null;
    private mailboxChannel: Ably.Types.RealtimeChannelPromise | null = null;
    private broadcastChannel: Ably.Types.RealtimeChannelPromise | null = null;
     agentId: string | null = null;
    private environmentId: string | null = null;
    private contributionFilter: ContributionFilter | null = null;
    private contributionFilterOptions: ContributionFilterOptions | undefined;


    async connect(credentials: string, agentId: string, environmentId: string): Promise<void> {
        this.ably = new Ably.Realtime({ key: credentials, clientId: agentId });
        this.agentId = agentId;
        this.environmentId = environmentId;

        this.mailboxChannel = await this.ably.channels.get(`agent:${this.agentId}:mailbox`);
        this.broadcastChannel = await this.ably.channels.get(`[?rewind=1]environment:${this.environmentId}:default`);

        // Subscribe to own mailbox
        this.mailboxChannel.subscribe((message: Ably.Types.Message) => {
            //console.log(`subscription message ${message}`);
            this.onMessage(message.data as FipaMessage);
        });

        // Subscribe to default channel
        this.broadcastChannel.subscribe((message: Ably.Types.Message) => {
            const fipaMessage = message.data as FipaMessage;
            if (message.name === 'contribution') {
                if(this.contributionFilter?.(fipaMessage)){
                    this.onContribution(fipaMessage);
                }
            } else {
                this.onMessage(message.data as FipaMessage);
            }
        });

        // Presence in default channel
        await this.broadcastChannel.presence.subscribe((presenceMsg: Ably.Types.PresenceMessage) => {
            //console.log(JSON.stringify(presenceMsg))
            const presenceEvent: PresenceEvent = {
                action: presenceActionMap[presenceMsg.action],
                agentId: presenceMsg.clientId
            }

            this.onPresenceEvent(presenceEvent);
        });

        // Enter default channel
        try {
           await this.broadcastChannel.presence.enter('Online')

        } catch (err) {
            if (err) {
                console.error(`Error entering the channel: ${err}`);
                return;
            }
        };


        if (this.contributionFilter) {
            if (this.broadcastChannel?.state === 'attached') {
               // this.checkHistory();
            }
        }
    }

    async disconnect(): Promise<void> {
        this.mailboxChannel?.unsubscribe();
        await this.broadcastChannel?.presence.leave();//() => {
        this.ably?.close();
        //});
        this.mailboxChannel = null;
        this.broadcastChannel = null;
    }

    async sendMessage(message: FipaMessage): Promise<void> {
        if (!message.receiver) {
            console.error('Receiver is not specified');
            return;
        }
        message.sender = this.agentId;
        const receiver = new Receiver(message.receiver);
        if (receiver.isBroadcast()) {
            await this.broadcastChannel.publish('broadcast', message);
            //console.log("Broadcast Message [ -> ] : " + JSON.stringify(message));
        } else if (receiver.isDirect()) {
            const channel = await this.ably.channels.get(`agent:${message.receiver}:mailbox`);
            await channel.publish('message', message);
            //console.log("Direct Message [ -> ] : " + JSON.stringify(message));
        } else {
            receiver.members().forEach(async (agentId: AgentID) => {
                const channel = await this.ably.channels.get(`agent:${agentId}:mailbox`);
                await channel.publish('message', message);
                //console.log("Direct Message [ -> ] : " + JSON.stringify(message));
            })
        }
    }

    async makeContribution(contributionMsg: FipaMessage): Promise<void> {
        contributionMsg.sender = this.agentId;
        await this.broadcastChannel?.publish('contribution', contributionMsg);
        //console.log("Contribute [ -> ] : " + JSON.stringify(contributionMsg));
    }

    async setContributionFilter(filter: ContributionFilter, options?: ContributionFilterOptions): Promise<void> {
        this.contributionFilter = filter;
        this.contributionFilterOptions = options;
        if (this.broadcastChannel?.state === 'attached') {
            //await this.checkHistory();
        }
    }
    async checkHistory() {
        // Optionally retrieve historical messages based on options

        const untilAttach = this.contributionFilterOptions?.untilAttach;
        const page = await this.broadcastChannel?.history({ limit: 1, untilAttach });//, (err, page) => {
        page?.items.forEach((message) => {
            const fipaMessage = message.data as FipaMessage;
            if (message.name === 'contribution') {//&& this.contributionFilter?.(fipaMessage)) {
                this.onContribution(fipaMessage);
            }
        });
        ;
    }

    abstract onMessage(message: FipaMessage): void;
    abstract onPresenceEvent(event: PresenceEvent): void;
    abstract onContribution(contribution: FipaMessage): void;
}

// Sample usage
//const credentials = '<YOUR_ABLY_API_KEY>'; // Or token

export class Agent extends AbstractAgent {

    constructor() {
        super();
    }

    onMessage(message: FipaMessage): void {
        console.log(`Received message: ${JSON.stringify(message)}`);
    }
    onPresenceEvent(event: PresenceEvent): void {
        console.log(`Received Presence Event: ${JSON.stringify(event)}`);
    }
    onContribution(contribution: FipaMessage): void {
        console.log(`Received contribution: ${JSON.stringify(contribution)}`);
    }

}